<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Activity</Text>
      <!-- <Btn
        @click="to('/dashboard/quiz/upload')"
        v-if="$store.getters.isAdmin"
        >Upload</Btn
      > -->
    </List>

    <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <!-- <div
          :class="{ active: list.section == 'docs' }"
          @click="list.section = 'docs'"
        >
          Docs
        </div> -->
        <div
          :class="{ active: list.section == 'all' }"
          @click="list.section = 'all'"
        >
          All
        </div>
        <!-- <div
          :class="{ active: list.section == 'automation' }"
          @click="list.section = 'automation'"
        >
          Automation
        </div>
        <div
          :class="{ active: list.section == 'API' }"
          @click="list.section = 'API'"
        >
          API
        </div>
        <div
          :class="{ active: list.section == 'web' }"
          @click="list.section = 'web'"
        >
          Web
        </div>
        <div
          :class="{ active: list.section == 'SDLC' }"
          @click="list.section = 'SDLC'"
        >
          SDLC
        </div>
        <div
          :class="{ active: list.section == 'draft' }"
          @click="list.section = 'draft'"
          v-if="$store.getters.isAdmin"
        >
          Draft
        </div> -->
      </div>
      <div el="input-list">
        <label el="input">
          <input
            type="text"
            placeholder="Search..."
            v-model="list.searchInput"
            el
          />
        </label>
      </div>
    </List>

    <table el="table">
      <thead>
        <tr>
          <!-- <th width="180px">Date</th> -->
          <th>Date</th>
          <th>User</th>
          <th>Collection</th>
          <th>Document</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr
          v-for="item in filteredList"
          :key="item"
          @click="to('/dashboard/quiz/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        > -->
        <tr
          v-for="item in filteredList"
          :key="item"
          :class="{ active: $route.params.id == item.id }"
        >
          <td>{{ formatDate(item.date) }}</td>
          <!-- <td>{{ item.date }}</td> -->
          <td>{{ item.userDisplayName }}</td>
          <td>{{ item.collection }}</td>
          <td>{{ item.label }}</td>
        </tr>
        <!-- <tr>
          <td>Lowis</td>
          <td>Lane</td>
        </tr> -->
      </tbody>
    </table>
  </List>
</template>
<script>
import router from "@/router";
let filter = require("lodash/filter");
let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      groups: this.$store.state.main.groups,
      list: {
        searchInput: "",
        section: "all",
        filters: {
          tag: "all",
          tags:[],
          status: "publish",
        },
      },
    };
  },
  watch: {
    "list.section": function (val) {
      this.list.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
    "groups.active": function (val) {
      this.list.filters.tags = [val];
    },
  },
  methods: {
    formatDate(d) {
      return d.toDate().toLocaleString();
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    filteredList() {
      // var input = this.modules.searchInput;
      let x = this;
      // let checker = (arr, target) => target.every((v) => arr.includes(v));
      return filter(this.$store.state.activity.list, function (item) {
          return (
            // checker(item.tags, x.list.filters.tags) == true &&
            // includes(item.tags, x.list.filters.tag) == true &&
            // item.status == "publish" &&
            lowerCase(`${item.userDisplayName} ${item.label} ${item.collection}`).search(
              lowerCase(x.list.searchInput)
            ) > -1
          );
      });
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.$store.commit("activity/loadList");
    this.list.filters.tags = [this.groups.active];
  },
};
</script>
<style lang="scss" scoped>
</style>
